<div class="container">
  <p>Vous avez fumé environ {{cigaretteCount | number: '1.0-0':'fr'}} cigarettes dans votre vie.</p>
  <p>Vous y avez consacré {{cigaretteCount * 7 / 60 | number: '1.0-0':'fr'}} heures</p>
  <p>Vous avez donné plus de <span class="hit">{{amountSpent | number: '1.0-0':'fr'}} €</span></p>
  <p>L'État vous remercie car il a collecté {{taxAmount | number: '1.0-0':'fr'}} € en taxes grâce à vous</p>
  <br/>
  <div class="content">
    <h2>Vous auriez pu :</h2>
    <p *ngIf="amountSpent > 500">Vous offrir {{price * dailyCount / 20 * 365 | number:'1.0-0':'fr'}} € de cadeaux à
      vous-même chaque année (rien que ça)</p>
    <p *ngIf="amountSpent > 6000">Voyager {{amountSpent / 3000 | number:'1.0-0':'fr'}} fois à Bali pour 3 semaines, avec
      vol & hotel 5*, all-inclusive</p>
    <p *ngIf="amountSpent > 350">Inviter {{amountSpent / 350 | number:'1.0-0':'fr'}} fois votre conjoint ou votre petite
      mamie à diner dans un somptueux restau gastronomique Michelin étoilé, avec accords mets-vin</p>
    <p *ngIf="amountSpent > 15">Eviter la contamination de {{amountSpent * 500 | number:'1.0-0':'fr'}} litres d'eau avec
      les mégots</p>
    <p *ngIf="amountSpent > 15">Aller voir {{amountSpent / 15 | number:'1.0-0':'fr'}} films au cinéma, avec lunettes 3D
      et IMAX</p>
  </div>
  <br/>
  <div class="content">
    <h2>Les stats qu'on oublie de vous dire</h2>
    <p *ngIf="years < 45">Vous avez une chance sur 2 d'atteindre la retraite.</p>
    <p>Les recettes liées au Tabac rapportent 20.6 milliards d'€ à l'état chaque année, grâce notamment aux économies
      réalisées sur les retraites des décès prématurés.</p>
    <p>Chaque année, c'est l'équivalent de 40 piscines olympiques remplies de mégots qui sont jetés en France et qui
      pollueront la planète pendant au moins 12 ans</p>
  </div>
  <!-- Your share button code -->
  <div class="fb-share-button"
       data-href="https://enfumee.hypnocurieux.com"
       data-layout="button"
       data-size="large">
  </div>
</div>


