import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {NumberFieldComponent} from './number-field/number-field.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'decide';
  pageForm: FormGroup;
  submitted = false;

  @ViewChild('firstCigaretteAgeInput', {static: false})
  firstCigaretteAgeComponent: NumberFieldComponent;
  @ViewChild('currentAgeInput', {static: false})
  currentAgeComponent: NumberFieldComponent;
  @ViewChild('dailyCountInput', {static: false})
  dailyCountComponent: NumberFieldComponent;
  @ViewChild('packPriceInput', {static: false})
  packPriceComponent: NumberFieldComponent;

  constructor(private formBuilder: FormBuilder) {
    this.pageForm = this.formBuilder.group({
      firstCigaretteAge: [null, Validators.compose([Validators.required, Validators.min(8), Validators.max(100)])],
      currentAge: [null, Validators.compose([Validators.required, Validators.min(12), Validators.max(100)])],
      dailyCount: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(100)])],
      packPrice: [9.10, Validators.compose([Validators.required, Validators.min(4), Validators.max(15)])],
    }, {validators: Validators.compose([this.currentAgeAboveFirstCigaretteAge()])});
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.firstCigaretteAgeComponent.focus();
  }

  get currentAge() {
    return this.pageForm.get('currentAge');
  }

  get firstCigaretteAge() {
    return this.pageForm.get('firstCigaretteAge');
  }

  get dailyCount() {
    return this.pageForm.get('dailyCount');
  }

  get packPrice() {
    return this.pageForm.get('packPrice');
  }


  currentAgeAboveFirstCigaretteAge(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const firstCigaretteAge: number = group.get('firstCigaretteAge').value;
      const currentAge: number =  group.get('currentAge').value;
      if (currentAge <= firstCigaretteAge) {
        return {'currentAge.below.firstCigaretteAge': {value: currentAge}};
      }
      return null;
    };
  }

  onSubmit() {
    this.submitted = true;
  }

  goTo(value: string) {
    switch (value) {
      case 'firstCigaretteAge':
        this.firstCigaretteAgeComponent.focus();
        break;
      case 'currentAge':
        this.currentAgeComponent.focus();
        break;
      case 'dailyCount':
        this.dailyCountComponent.focus();
        break;
      case 'packPrice':
        this.packPriceComponent.focus();
        break;
      case 'submit':
        break;
    }
  }

}
