import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-calculator-results',
  templateUrl: './calculator-results.component.html',
  styleUrls: ['./calculator-results.component.scss']
})
export class CalculatorResultsComponent implements OnInit, OnChanges {

  @Input()
  price: number;
  @Input()
  dailyCount: number;
  @Input()
  years: number;

  cigaretteCount: number;
  amountSpent: number;
  taxAmount: number;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.amountSpent = 0;
    this.taxAmount = 0;
    this.cigaretteCount = 0;
    if (this.price && this.dailyCount && this.years) {
      this.cigaretteCount = 365 * this.years * this.dailyCount;
      let adjustedPrice = this.price;
      for (let i = 0; i < this.years; i++) {
        this.amountSpent += Math.floor(adjustedPrice * this.dailyCount * 365 / 20);
        adjustedPrice *= 0.95;
      }
      this.taxAmount = Math.floor(this.amountSpent * 0.80);
    }
  }


}
