import {EventEmitter} from '@angular/core';
import {Subscription} from 'rxjs';

export class TextFieldBrowser {
  private static KEY_BACKSPACE = 'Backspace';
  private static KEY_TAB = 'Tab';
  private static KEY_LEFT = 'ArrowLeft';
  private static KEY_RIGHT = 'ArrowRight';
  static KEY_UP = 'ArrowUp';
  static KEY_DOWN = 'ArrowDown';

  private maxLength: number;
  private value: string;
  private goToPrevious: EventEmitter<any> = new EventEmitter<any>();
  private goToNext: EventEmitter<any> = new EventEmitter<any>();
  private previousSubscription: Subscription;
  private nextSubscription: Subscription;

  constructor(maxLength: number) {
    this.maxLength = maxLength;
  }

  subscribe(goToPrevious: EventEmitter<any>, goToNext: EventEmitter<any>) {
    this.previousSubscription = this.goToPrevious.subscribe(() => {
      goToPrevious.emit();
    });
    this.nextSubscription = this.goToNext.subscribe(() => {
      goToNext.emit();
    });
  }

  unsubscribe() {
    this.previousSubscription.unsubscribe();
    this.nextSubscription.unsubscribe();
  }

  onChange(newValue: string) {
    this.value = newValue;
    if (newValue.length === this.maxLength) {
      this.goToNext.emit();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    switch (event.key) {
      case TextFieldBrowser.KEY_BACKSPACE:
        if (!this.value || this.value.length === 0) {
          this.goToPrevious.emit();
        }
        break;
      case TextFieldBrowser.KEY_LEFT:
        if (target.selectionStart === 0) {
          this.goToPrevious.emit();
        }
        break;
      case TextFieldBrowser.KEY_RIGHT:
        if (!this.value || target.selectionEnd === this.value.length) {
          this.goToNext.emit();
        }
        break;
    }
  }
}
