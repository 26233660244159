<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .content {
    display: flex;
    margin: 10px auto 32px;
    padding: 0;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
  }

</style>

<div class="content" role="main">

  <!-- Highlight Card -->
  <img src="assets/logo.png" alt="Logo Hypno-Curieux" class="logo"/>
  <h1>Mais ça fait combien exactement ?</h1>
  <h3>Calculer mes dépenses totales en tabac</h3>

  <!-- Resources -->
  <form [formGroup]="pageForm" (ngSubmit)="onSubmit()">
    <div>
      <h2>Age de ma 1ère cigarette</h2>
      <app-number-field label="ans" [errors]="firstCigaretteAge.errors" (goToPrevious)="goTo('submit')" (goToNext)="goTo('currentAge')"
                     formControlName="firstCigaretteAge" #firstCigaretteAgeInput></app-number-field>
    </div>
    <div>
      <h2>Age actuel</h2>
      <app-number-field label="ans" [errors]="currentAge.errors" (goToPrevious)="goTo('firstCigaretteAge')" (goToNext)="goTo('dailyCount')"
                     formControlName="currentAge" #currentAgeInput></app-number-field>
    </div>
    <div class="break"></div>
    <div>
      <h2>Nb de cigarettes par jour</h2>
      <app-number-field label="/jr" [errors]="dailyCount.errors" (goToPrevious)="goTo('currentAge')" (goToNext)="goTo('packPrice')"
                        formControlName="dailyCount" #dailyCountInput></app-number-field>
    </div>
    <div>
      <h2>Prix du paquet (en 2020)</h2>
      <app-number-field label="€" size="4" [errors]="packPrice.errors" (goToPrevious)="goTo('dailyCount')" (goToNext)="goTo('submit')"
                        formControlName="packPrice" #packPriceInput></app-number-field>
    </div>
    <div class="break"></div>
    <div class="submit">
      <button mat-raised-button color="primary" [disabled]="!pageForm.valid" type="submit">Calculer</button>
      <br/><br/>
    </div>
    <app-calculator-results *ngIf="submitted" [price]="packPrice.value" [dailyCount]="dailyCount.value" [years]="currentAge.value - firstCigaretteAge.value"></app-calculator-results>
  </form>

  <div>

  </div>
  <!-- Footer -->
  <footer>
    <hr/>
    <div class="container">
      <p>Ce site ne collecte aucune donnée personnelle</p>
      <p>Le calculateur tient compte des augmentations des prix moyens chaque année</p>
      <p>Page développée par Loïc Ortola pour le meetup <a
        href="https://www.meetup.com/Hypno-Curieux/" target="_blank">Hypno-Curieux</a></p>
      <div>Vous avez toujours rêvé de vous libérer de la cigarette mais c'est plus fort que vous  ?
        <br/>
        <a href="http://hypnocurieux.com" target="_blank" rel="noopener">
          Je m'en débarrasse avec l'hypnose
        </a>
      </div>
    </div>
  </footer>

</div>

<router-outlet></router-outlet>
